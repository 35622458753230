<template>
	<div>
		<Modal :options="{width: '95vw', close: true}"  @close="$emit('close')">
            <div class="title">Reestructurar</div>
            <div class="body">
                <div class="col-12 row">
                    <div class="col-6">
                        <fieldset>
                            <legend>Créditos del Cliente en Core</legend>
                            <div v-if="cliente_existente.length > 0" class="row form-group">
                                <h5 class="p-2"> <strong>Información del Cliente</strong></h5>
                                <table class="table table-responsive table-bordered">
                                    <thead>
                                        <th>Seleccionar</th>
                                        <th>Nombre Completo</th>
                                        <th>RFC</th>
                                        <th>Curp</th>
                                        <th>Régimen</th>
                                        <th>Tipo</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cliente in cliente_existente" :key="cliente.id">
                                            <td><Radio v-model="cliente_asignado" name="clientes_core" :id="'clientes_core_'+(cliente.id)" :value="$helper.toJson(cliente)" /></td>
                                            <td> {{ cliente.person.firstName+' ' +(cliente.person.secondName ? cliente.person.secondName+' ' : '' ) + cliente.person.lastName+' ' +(cliente.person.secondLastName ? cliente.person.secondLastName : '' )}} </td>
                                            <td>{{ cliente.person.fiscalIdentity }}</td>
                                            <td>{{ cliente.person.personalIdentity }}</td>
                                            <td>{{ cliente.code == 'PF' ? 'Persona fisica' : 'Persona moral' }}</td>
                                            <td>{{ cliente.customerType }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="cliente_asignado" class="row form-group">
                                <h5 class="p-2"> <strong>Creditos Disponibles en Core</strong></h5>
                                <table class="table table-bordered">
                                    <thead>
                                        <th>Seleccionar</th>
                                        <th>Folio</th>
                                        <th>Monto</th>
                                        <th>Tipo</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(credito, index) in cliente_asignado_data.creditos" :key="credito.id">
                                            <td>
                                                <Radio v-model="credito_asignado" name="folio_credito" :id="'folio_credito'+(index+1)" :value="$helper.toJson(credito)" />
                                            </td>
                                            <td>{{ credito.folio }}</td>
                                            <td>${{ $helper.moneyFormat(credito.amount) }}</td>
                                            <td>{{ credito.product.name }}</td>
                                        </tr>
                                    </tbody>
                                </table>                                       
                            </div>                       
                        </fieldset>
                        <template v-if="cliente_asignado">
                            <fieldset>
                                <legend>Créditos Actuales del Cliente</legend>
                                    <h5 class="p-2"> <strong>Crédito a Reemplazar</strong></h5>
                                    <table class="table table-bordered">
                                        <thead>
                                            <th>Seleccionar</th>
                                            <th>Folio</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(credito, index) in cliente.creditos" :key="credito.id">
                                                <td>
                                                    <Radio v-model="credito_a_reemplazar" name="folio_credito_reemplazar" :id="'folio_credito_reemplazar'+(index+1)" :value="$helper.toJson(credito)" />
                                                </td>
                                                <td>{{ credito.folio }}</td>
                                            </tr>
                                        </tbody>
                                    </table>                          
                            </fieldset>
                        </template> 
                    </div>
                    <div class="col-6">
                        <fieldset>
                            <legend>Información para el Crédito reestructurado</legend>
                            <div v-if="credito_a_reemplazar" >
                                <h5 class="p-2"> <strong>Aquí puedes actualizar la información del crédito</strong></h5>                 
                                <div class="row form-group p-2">
                                    <label for="nombre" class="col-form-label col-sm-2">Solicitud ID</label>
                                    <div class="col-sm-4"><input v-model="informacion_credito.solicitud_id" type="text" name="nombre" id="nombre" class="form-control"></div>                 
                                    <label for="email" class="col-form-label col-sm-2">Plazo <small>(años)</small></label>
                                    <div class="col-sm-4"><input v-model="informacion_credito.plazo" type="email" name="text" id="email" class="form-control"></div>                      
                                </div>
                                <div class="row form-group p-2">
                                    <label for="nombre" class="col-form-label col-sm-2">Importe Solicitado</label>
                                    <div class="col-sm-4"><InputCompuesto v-model="informacion_credito.importe_solicitado" label="$" name="detalles.precio" id="detalles.precio" :formats="['moneyFormat']"/></div>
                                    <label for="email" class="col-form-label col-sm-2">Importe Selccionado</label>
                                    <div class="col-sm-4"><InputCompuesto v-model="informacion_credito.importe_seleccionado" label="$" name="detalles.precio" id="detalles.precio" :formats="['moneyFormat']"/></div>
                                </div>
                                <div class="row form-group p-2">
                                    <label for="nombre" class="col-form-label col-sm-2">Importe Solicitado</label>
                                    <div class="col-sm-4"><InputCompuesto v-model="informacion_credito.importe_pre_autorizado" label="$" name="detalles.precio" id="detalles.precio" :formats="['moneyFormat']"/></div>
                                    <label for="email" class="col-form-label col-sm-2">Importe Selccionado</label>
                                    <div class="col-sm-4"><InputCompuesto v-model="informacion_credito.promotor_id" label="$" name="detalles.precio" id="detalles.precio" :formats="['moneyFormat']"/></div>
                                </div>
                                <div class="row form-group p-2">
                                    <label for="estado" class="col-form-label col-sm-2">Acepto importe preautorizado</label>
                                    <div class="col-sm-4">
                                        <select v-model="informacion_credito.acepto_importe_pre_autorizado" name="direccion.estado" id="direccion.estado" class="form-control" >
                                            <option value="null">Selecciona una opción</option>
                                            <option value="1">Sí</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>                 
                                    <label for="estado" class="col-form-label col-sm-2">Acepta políticas de privacidad</label>
                                    <div class="col-sm-4">
                                        <select v-model="informacion_credito.acepta_politicas_privacidad" name="direccion.estado" id="direccion.estado" class="form-control" >
                                            <option value="null">Selecciona una opción</option>
                                            <option value="1">Sí</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>                           
                                </div>
                                <div class="row form-group p-2">
                                    <label for="nombre" class="col-form-label col-sm-3">Destino del crédito ejercido</label>
                                    <div class="col-sm-9"><input v-model="informacion_credito.destino_credito_ejercido" type="text" name="destino_credito_ejercido" id="destino_credito_ejercido" class="form-control"></div>                 
                                    <label for="nombre" class="col-form-label col-sm-3">Descripción del giro de negocio</label>
                                    <div class="col-sm-9"><input v-model="informacion_credito.descripcion_giro_negocio" type="text" name="destino_credito_ejercido" id="destino_credito_ejercido" class="form-control"></div>  
                                    <label for="nombre" class="col-form-label col-sm-3">Descripción de políticas</label>
                                    <div class="col-sm-9"><input v-model="informacion_credito.descripcion_politicas" type="text" name="destino_credito_ejercido" id="destino_credito_ejercido" class="form-control"></div>                 
                                    <label for="email" class="col-form-label col-sm-3">Descripción de la solicitud</label>
                                    <div class="col-sm-9"><input v-model="informacion_credito.descripcion_solicitud" type="text" name="descripcion_giro_negocio" id="descripcion_giro_negocio" class="form-control"></div>                      
                                </div> 
                            </div>      
                            <div v-else class="row form-group">
                                <div class="col-sm-12">Selecciona el credito a reemplazar para mostrar su información</div>
                            </div> 
                        </fieldset>

                    </div>

                </div>
            </div>
            <div class="footer">
                <div class="row">
                <div class="col-12 text-right">
                    <button class="btn btn-primary mr-2" @click="reestructurar_credito">Reestructurar Crédito</button>
                    <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
                </div>
                </div>
            </div>
		</Modal>
	</div>
</template>

<script>

import Modal from '@/components/Modal';
import api from '@/apps/clientes/api/clientes';
import Radio from '@/components/Form/Radio';
import InputCompuesto from '@/components/Form/InputCompuesto';

    export default {
        components: {
            Modal, Radio, InputCompuesto
        },
        props:{
            informacion_cliente:null
        },
        data(){
            return{
                cliente:null,
                cliente_existente:[],
                cliente_asignado:null,
                credito_asignado:null,
                credito_a_reemplazar:null,
                informacion_credito:{
                    folio:null,
                    no_credito:null,
                    solicitud_id:null,
                    plazo:null,
                    importe_solicitado:null,
                    importe_seleccionado:null,
                    importe_pre_autorizado:null,
                    promotor_id:null,
                    acepto_importe_pre_autorizado:null,
                    acepta_politicas_privacidad:null,
                    destino_credito_ejercido:null,
                    descripcion_giro_negocio:null,
                    descripcion_politicas:null,
                    descripcion_solicitud:null,
                }
            }
        },
        mounted(){
            // this.informacion_cliente.curp = null;
            // this.informacion_cliente.rfc = null;
            if(this.informacion_cliente && this.informacion_cliente !==null){
                this.cliente = this.informacion_cliente;
                this.buscar_cliente();
            }

        },
        methods:{
            async buscar_cliente(){
				let cliente = [];

				if(this.cliente.rfc){
                    cliente =  (await api.buscar_cliente({search: this.cliente.rfc})).data;
					this.$log.info('cliente rfc', cliente);
				}
					
				if(cliente.length === 0 && this.cliente.curp && this.cliente.curp !== null){
                    cliente = (await api.buscar_cliente({search: this.cliente.curp})).data;
                    this.$log.info('cliente curp', cliente);
				}
				
				if(cliente.length === 0){
					return this.$helper.showMessage('Cliente No Encontrado','El cliente no esta registrado en nuestro sistema, es necesario asignar la solicitud a un cliente nuevo','error','alert'); 
				}

				this.cliente_existente = cliente;
				cliente = null;                
            },
            async reestructurar_credito(){
                if(this.cliente_asignado === null|| this.credito_asignado === null|| this.credito_a_reemplazar === null)
					this.$helper.showMessage('Información incompleta','Para reestructurar un crédito es necesario seleccionar el cliente, el crédito ','error','alert'); 

                try {
                    // this.$log.info('credito nuevo', this.cliente);
                    this.informacion_credito.folio = this.$helper.fromJson(this.credito_asignado).folio;
                    this.informacion_credito.no_credito = this.$helper.fromJson(this.credito_asignado).id;
                    await api.usuarios_reestructurar_credito(this.$helper.fromJson(this.credito_a_reemplazar).id, this.informacion_credito);
					this.$helper.showMessage('Crédito Reestructurado','El crédito ha sido reestructurado de forma exitosa');

                    this.$emit('update', this.cliente.id);
                    this.$emit('close');
                    
                } catch (error) {
                    this.$log.info('error', error);
                    this.$helper.showAxiosError(error, 'Error');
                }
            },
            informacion_formulario_credito(){
                if(this.credito_a_reemplazar && this.credito_a_reemplazar !== null){
                    let informacion_credito_nuevo = this.$helper.fromJson(this.credito_a_reemplazar);
                    this.informacion_credito.solicitud_id = informacion_credito_nuevo.solicitud_id;
                    this.informacion_credito.plazo = informacion_credito_nuevo.plazo;
                    this.informacion_credito.importe_solicitado = informacion_credito_nuevo.importe_solicitado;
                    this.informacion_credito.importe_seleccionado = informacion_credito_nuevo.importe_seleccionado;
                    this.informacion_credito.importe_pre_autorizado = informacion_credito_nuevo.importe_pre_autorizado;
                    this.informacion_credito.promotor_id = informacion_credito_nuevo.promotor_id;
                    this.informacion_credito.acepto_importe_pre_autorizado = informacion_credito_nuevo.acepto_importe_pre_autorizado;
                    this.informacion_credito.acepta_politicas_privacidad = informacion_credito_nuevo.acepta_politicas_privacidad;
                    this.informacion_credito.destino_credito_ejercido = informacion_credito_nuevo.destino_credito_ejercido;
                    this.informacion_credito.descripcion_giro_negocio = informacion_credito_nuevo.descripcion_giro_negocio;
                    this.informacion_credito.descripcion_politicas = informacion_credito_nuevo.descripcion_politicas;
                    this.informacion_credito.descripcion_solicitud = informacion_credito_nuevo.descripcion_solicitud;
                }
            }            
        },
        computed: {
			cliente_asignado_data: function() {
				return this.$helper.fromJson(this.cliente_asignado);
			},
		},
        watch:{
            credito_a_reemplazar(){
                this.informacion_formulario_credito();
            }
        }
};

	
</script>

<style lang="scss" scoped>
	.table {
		width: 100%;

		thead {
			background-color: #333333 !important;

			th {
				padding: 5px 10px;
			}
		}

		tbody {
			tr:nth-child(2n+0) {
				background-color: #E6E6E6;
			}

			tr {
				td {
					padding: 5px 10px;
				}
			}
		}
	}

    .border-table{
    border-width:1px !important;
}
</style>